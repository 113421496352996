import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

//MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';

//PAGES
import Home from './pages/Home.js';
import Services from './pages/Services.js';
import Projects from './pages/Projects.js';
import About from './pages/About.js';
import Calculator from './pages/ProjectCalculatorFinal.js';
import Login from './pages/Login.js';
import Signup from './pages/Signup.js';

const theme = createTheme({
  palette: {
    primary: {
      light: '#252525',
      main: '#252525',
      dark: '#252525',
      mainText: '#FFFFFF',
      secondaryText: '#C453B8',
      glossy:'rgba(35, 35, 35, 0.58)'
    },
    secondary: {
      light: '#FFFFFF',
      main: '#F9F9F9',
      dark: '#EFEFEF',
      mainText: '#1D1D1D',
      secondaryText: '#E0AF2A'
    },
  },
  typography: {
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/projects" element={<Projects/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/calculator" element={<Calculator/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/signup" element={<Signup/>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
