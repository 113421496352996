import React from 'react';

//MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

//const client = process.env.REACT_APP_CLIENT_URL;
const calendlyMeetingLink = process.env.REACT_APP_CALENDLY_MEETING_LINK;

const CalculatorForm = (props)=>{

  const handleScheduleMeeting = ()=> {
    window.open(`${calendlyMeetingLink}`, '_blank');
  }

  /*
  const handleStartProject = ()=>{
    navigate(`/signup`)
  }*/

  const handleEmail = () => {
    window.location.href = `mailto:tmdevelop17@gmail.com`;
  };

  return(
    props.isMobile
    ?
    //MOBILE
        <Box sx={{width:'100%', height:'32vh', backgroundColor:'#232323', borderRadius:'2em'}}>
          <Grid container  spacing={1} sx={{height:'98%'}}>
            <Grid xs={12} height={5}></Grid>
            <Grid xs={1}></Grid>
              <Grid xs={10}>
              <Typography variant="h5" color="primary.mainText" sx={{ fontSize:{xs:'100%', sm:'130%'}, textAlign:'center', fontWeight:'bold'}}>
              {props.questions[props.formCategory][props.activeStep].questionLabel}
              </Typography>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid xs={1}></Grid>
              <Grid xs={10} sx={{display:'flex', flexDirection:'column', overflowY:'scroll', height:'10vh'}}>
                {props.questions[props.formCategory][props.activeStep].questionChoices.map((choiceButton,index)=>{
                  return(
                  <Button key={`${choiceButton}-${index}`} onClick={()=>{
                    props.activeStep + 2 === props.questions[props.formCategory].length  
                    ?props.changePaymentMethod(index)
                    :props.setCalculationFunction(index,props.questions[props.formCategory][props.activeStep].questionChoicesValue[index],props.questions[props.formCategory][props.activeStep].questionItemName[index])
                    }} sx={{margin:'auto', display:'flex', flexDirection:'column'}}>
                    <img src={process.env.PUBLIC_URL +'/images/' + props.questions[props.formCategory][props.activeStep].questionChoicesImage[index]} alt={`${choiceButton}`} style={{ margin:'auto', height:'10vw',width:'10vw'}}/>
                    <Typography variant="h6" color="primary.mainText" sx={{ fontSize:{xs:'0.8em', sm:'1em%'}, width:'70%', textAlign:'center', fontWeight:'bold'}}>
                    {choiceButton}
                    </Typography>
                  </Button>)
                })}
                {
                  props.activeStep + 1 === props.questions[props.formCategory].length 
                  &&
                  <React.Fragment>
                  {/*<Button onClick={handleStartProject} sx={{margin:'auto', display:'flex', flexDirection:'column'}}>
                    <img src={process.env.PUBLIC_URL +'/images/StartFinal.png'} alt={`start making your app`} style={{ margin:'auto', height:{md:'18vh', xl:'20vh'}}}/>
                    <Typography variant="h6" color="primary.mainText" sx={{ fontSize:{md:'80%', xl:'120%'}, width:'70%', textAlign:'center', fontWeight:'bold'}}>
                    Start making your project
                    </Typography>
                  </Button>*/}
                  <Button onClick={handleEmail} sx={{margin:'auto', display:'flex', flexDirection:'column'}}>
                    <img src={process.env.PUBLIC_URL +'/images/EmailUsFinal.png'} alt={`start making your app`} style={{ margin:'auto', height:'10vw',width:'10vw'}}/>
                    <Typography variant="h6" color="primary.mainText" sx={{ fontSize:{xs:'0.8em', sm:'1em'}, width:'70%', textAlign:'center', fontWeight:'bold'}}>
                    Email Us
                    </Typography>
                  </Button>
                  <Button onClick={handleScheduleMeeting} sx={{margin:'auto', display:'flex', flexDirection:'column'}}>
                    <img src={process.env.PUBLIC_URL +'/images/ScheduleMeetingFinal.png'} alt={`start making your app`} style={{ margin:'auto', height:'10vw',width:'10vw'}}/>
                    <Typography variant="h6" color="primary.mainText" sx={{ fontSize:{xs:'0.8em', sm:'1em'}, width:'70%', textAlign:'center', fontWeight:'bold'}}>
                    Schedule a meeting
                    </Typography>
                  </Button>
                  </React.Fragment>
                  
                }
              </Grid>
              <Grid xs={1}></Grid>
            <Grid xs={12} height={2.5}></Grid>
            <Grid xs={1}></Grid>
              <Grid xs={10} sx={{display:'flex'}}>
                {
                  props.activeStep > 0 
                  &&<Button onClick={props.undoCalculationFunction} sx={{padding:'2% 5%', margin:'auto', display:'flex', flexDirection:'column', backgroundColor:'white'}}>
                  Back
                </Button>
                }
                <Typography color="primary.mainText" sx={{ margin:'auto',fontSize:{md:'80%', xl:'120%'}, width:'60%', textAlign:'center', fontWeight:'bold'}}>
                  {props.activeStep + 1} / {Number(props.questions[props.formCategory].length)}
                </Typography>
                <Button onClick={props.resetCalculationFunction} sx={{padding:'2% 5%', margin:'auto', display:'flex', flexDirection:'column', backgroundColor:'white'}}>
                  Reset
                </Button>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid xs={12} height={5}></Grid>
          </Grid>
        </Box>
    :
    //PC
    <Box sx={{width:'100%', height:'55vh', backgroundColor:'#232323', borderRadius:'2em'}}>
          <Grid container  spacing={1} sx={{overflow:'scroll', height:'98%'}}>
            <Grid xs={12} height={5}></Grid>
            <Grid xs={1}></Grid>
              <Grid xs={10}>
              <Typography variant="h5" color="primary.mainText" sx={{ fontSize:{md:'180%', xl:'230%'}, textAlign:'center', fontWeight:'bold'}}>
              {props.questions[props.formCategory][props.activeStep].questionLabel}
              </Typography>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid xs={1}></Grid>
              <Grid xs={10} sx={{display:'flex'}}>
                {props.questions[props.formCategory][props.activeStep].questionChoices.map((choiceButton,index)=>{
                  return(
                  <Button key={`${choiceButton}-${index}`} onClick={()=>{
                    props.activeStep + 2 === props.questions[props.formCategory].length  
                    ?props.changePaymentMethod(index)
                    :props.setCalculationFunction(index,props.questions[props.formCategory][props.activeStep].questionChoicesValue[index],props.questions[props.formCategory][props.activeStep].questionItemName[index])
                    }} sx={{margin:'auto', display:'flex', flexDirection:'column'}}>
                    <img src={process.env.PUBLIC_URL +'/images/' + props.questions[props.formCategory][props.activeStep].questionChoicesImage[index]} alt={`${choiceButton}`} style={{ margin:'auto', height:{md:'18vh', xl:'20vh'}}}/>
                    <Typography variant="h6" color="primary.mainText" sx={{ fontSize:{md:'80%', xl:'120%'}, width:'70%', textAlign:'center', fontWeight:'bold'}}>
                    {choiceButton}
                    </Typography>
                  </Button>)
                })}
                {
                  props.activeStep + 1 === props.questions[props.formCategory].length 
                  &&
                  <React.Fragment>
                  {/*<Button onClick={handleStartProject} sx={{margin:'auto', display:'flex', flexDirection:'column'}}>
                    <img src={process.env.PUBLIC_URL +'/images/StartFinal.png'} alt={`start making your app`} style={{ margin:'auto', height:{md:'18vh', xl:'20vh'}}}/>
                    <Typography variant="h6" color="primary.mainText" sx={{ fontSize:{md:'80%', xl:'120%'}, width:'70%', textAlign:'center', fontWeight:'bold'}}>
                    Start making your project
                    </Typography>
                  </Button>*/}
                  <Button onClick={handleEmail} sx={{margin:'auto', display:'flex', flexDirection:'column'}}>
                    <img src={process.env.PUBLIC_URL +'/images/EmailUsFinal.png'} alt={`start making your app`} style={{ margin:'auto', height:{md:'18vh', xl:'20vh'}}}/>
                    <Typography variant="h6" color="primary.mainText" sx={{ fontSize:{md:'80%', xl:'120%'}, width:'70%', textAlign:'center', fontWeight:'bold'}}>
                    Email Us
                    </Typography>
                  </Button>
                  <Button onClick={handleScheduleMeeting} sx={{margin:'auto', display:'flex', flexDirection:'column'}}>
                    <img src={process.env.PUBLIC_URL +'/images/ScheduleMeetingFinal.png'} alt={`start making your app`} style={{ margin:'auto', height:{md:'18vh', xl:'20vh'}}}/>
                    <Typography variant="h6" color="primary.mainText" sx={{ fontSize:{md:'80%', xl:'120%'}, width:'70%', textAlign:'center', fontWeight:'bold'}}>
                    Schedule a meeting
                    </Typography>
                  </Button>
                  </React.Fragment>
                  
                }
              </Grid>
              <Grid xs={1}></Grid>
            <Grid xs={12} height={2.5}></Grid>
            <Grid xs={1}></Grid>
              <Grid xs={10} sx={{display:'flex'}}>
                {
                  props.activeStep > 0 
                  &&<Button onClick={props.undoCalculationFunction} sx={{padding:'2% 5%', margin:'auto', display:'flex', flexDirection:'column', backgroundColor:'white'}}>
                  Back
                </Button>
                }
                <Typography color="primary.mainText" sx={{ margin:'auto',fontSize:{md:'80%', xl:'120%'}, width:'60%', textAlign:'center', fontWeight:'bold'}}>
                  {props.activeStep + 1} / {Number(props.questions[props.formCategory].length)}
                </Typography>
                <Button onClick={props.resetCalculationFunction} sx={{padding:'2% 5%', margin:'auto', display:'flex', flexDirection:'column', backgroundColor:'white'}}>
                  Reset
                </Button>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid xs={12} height={5}></Grid>
          </Grid>
        </Box>
  )
}


export default CalculatorForm;