import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//COMPONENT
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';

//MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Home = () => {
  const navigate = useNavigate();

  const [madeText, setMadeText] = useState('Apps');
  const [madeBackgroundUrl, setMadeBackgroundUrl] = useState('AppBackground.png');
  const [madeBackgroundUrlMobile, setMadeBackgroundUrlMobile] = useState('AppBackground-Mobile.png');

  useEffect(() => {
    const texts = ['Apps', 'Websites', 'Games'];
    const imagesUrl = ['AppBackground.png', 'WebsiteBackground.png','GamesBackground.png'];
    const imagesUrlMobile = ['AppBackground-Mobile.png', 'WebsiteBackground-Mobile.png','GameBackground-Mobile.png'];
    let i = 0;
    const intervalId = setInterval(() => {
      setMadeText(texts[i]);
      setMadeBackgroundUrl(imagesUrl[i])
      setMadeBackgroundUrlMobile(imagesUrlMobile[i])
      i = (i + 1) % texts.length;
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const letUsHelpYou = ()=>{
    navigate(`/services`)
  }
    
    return (
      <Box sx={{ flexGrow: 1 , overflowX:'hidden'}}>
            {/*NAVBAR*/}
            <Navbar activePage='home'/>

            {/*PC BODY*/}
            <Grid container sx={{display:{xs: 'none', md: 'flex'}}}>
              <Grid xs={12} height={20}></Grid>
              <Grid xs={1}></Grid>
              <Grid xs={10}>
                <Box 
                  sx={{ 
                    p: 2, 
                    backgroundImage:`url(${process.env.PUBLIC_URL + '/images/' + madeBackgroundUrl})`, 
                    height:'75vh',
                    backgroundRepeat:'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5vh'
                  }}
                >
                  <Grid container >
                    <Grid xs={12} height={40}></Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={5}>
                      <Typography variant="h1" sx={{ color:'#FFFFFF', fontSize:'12vh', fontWeight:'bold'}}>We</Typography>
                    </Grid>
                    <Grid xs={6}></Grid>
                    <Grid xs={12} height={30}></Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={5}>
                      <Typography variant="h1" sx={{ color:'#FFFFFF', fontSize:'12vh', fontWeight:'bold'}}>make</Typography>
                    </Grid>
                    <Grid xs={6}></Grid>
                    <Grid xs={12} height={30}></Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={5}>
                      <Typography variant="h1" sx={{ background: '-webkit-linear-gradient(#C453B8, #95449C)', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', fontSize:'10vh', fontWeight:'bold'}}gutterBottom>{madeText}</Typography>
                    </Grid>
                    <Grid xs={6}></Grid>
                    <Grid xs={12} height={40}></Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={5}>
                      <Button onClick={letUsHelpYou} variant="contained" sx={{background: '-webkit-linear-gradient(#C453B8, #95449C)', p:'2.5vh 6vh 2.5vh 6vh', fontSize:'2.5vh', borderRadius:'5vh', '&:hover':{background: '-webkit-linear-gradient(#EE7EF0, #95449C)'} }}>Let us help you.</Button>
                    </Grid>
                    <Grid xs={6}></Grid>
                  </Grid>
                  </Box>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>

            {/*MOBILE BODY*/}
            <Grid container sx={{display:{xs: 'flex', md: 'none'}}}>
              <Grid xs={1}></Grid>
              <Grid xs={10}>
                <Box 
                  sx={{ 
                    p: 2, 
                    backgroundImage:`url(${process.env.PUBLIC_URL + '/images/' + madeBackgroundUrlMobile})`, 
                    height:'70vh',
                    backgroundRepeat:'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5vh',
                    backgroundPosition: {xs:'center 20%', sm:'center 60%'},
                  }}
                >
                  <Grid container >
                    <Grid xs={12} height={30}></Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={10}>
                      <Typography variant="h1" sx={{ textAlign:'center', color:'#FFFFFF', fontSize:'6vh', fontWeight:'bold'}}>We</Typography>
                    </Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={12} height={20}></Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={10}>
                      <Typography variant="h1" sx={{textAlign:'center', color:'#FFFFFF', fontSize:'6vh', fontWeight:'bold'}}>make</Typography>
                    </Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={12} height={20}></Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={10}>
                      <Typography variant="h1" sx={{textAlign:'center', background: '-webkit-linear-gradient(#C453B8, #95449C)', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', fontSize:'5vh', fontWeight:'bold'}}gutterBottom>{madeText}</Typography>
                    </Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={12} height={20}></Grid>
                    <Grid xs={1}></Grid>
                    <Grid xs={10} sx={{display:'flex'}}>
                      <Button onClick={letUsHelpYou} variant="contained" sx={{margin:'auto', background: '-webkit-linear-gradient(#C453B8, #95449C)', p:'2.5vh 6vh 2.5vh 6vh', fontSize:'1.5vh', borderRadius:'5vh', '&:hover':{background: '-webkit-linear-gradient(#EE7EF0, #95449C)'} }}>Let us help you.</Button>
                    </Grid>
                    <Grid xs={1}></Grid>
                  </Grid>
                  </Box>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>

            {/*FOOTER*/}
            <Footer/>
      </Box>
    );
};

export default Home;
