import React from 'react';

//MUI
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Box } from '@mui/material';


const Footer = () => {
    
    return (
        <Grid container sx={{display:'flex'}}>
        <Grid xs={1}></Grid>
        <Grid xs={10}>
          <Box >
            <Grid container>
            <Grid xs={12} height={20}></Grid>
              <Grid xs={1}></Grid>
              <Grid xs={10} sx={{display:'flex'}}>
              <Typography variant="h6" sx={{ background: '-webkit-linear-gradient(#C453B8, #95449C)', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', fontSize:{xs:'1.5vh',md:'2vh'}, fontWeight:'bold', margin:'auto'}}gutterBottom>All Right Reserved to TMDevelop 2024</Typography>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={1}></Grid>
      </Grid>
    );
};

export default Footer;
