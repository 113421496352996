import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//COMPONENT
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';

//MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

const CssTextField = styled(TextField)({
  '& .MuiFormLabel-asterisk': {
    color: '#EE7EF0',
  },
  '& .MuiFormLabel-root': {
    color: '#EE7EF0',
  },
  '& .MuiInputBase-input': {
    color: '#EE7EF0',
  },
  '& label.Mui-focused': {
    color: '#EE7EF0',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#EE7EF0',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#EE7EF0',
      borderRadius:'2em'
    },
    '&:hover fieldset': {
      borderColor: '#EE7EF0',
      borderRadius:'2em'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#EE7EF0',
      borderRadius:'2em'
    },
  },
});

const Projects = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setrememberMe] = useState(false);


  const handleLogin = ()=>{

  }

  const handleForgetPassword = ()=>{
    
  }

  const handleSignup = ()=>{
    navigate('/signup')
  }

  const handleChangeEmail = (event)=>{
    setEmail(event.target.value);
  }

  const handleChangePassword = (event)=>{
    setPassword(event.target.value);
  }

  const handleChangeRememberMe = ()=>{
    setrememberMe(!rememberMe);
  }
  
    return (
      <Box sx={{ flexGrow: 1 , overflowX:'hidden'}}>
            {/*NAVBAR*/}
            <Navbar activePage='login'/>

            {/*PC BODY*/}
            <Grid container xs={12} sx={{display:{xs: 'none', md: 'flex'}}}>
              <Grid xs={12} height={20}></Grid>
              <Grid xs={1}></Grid>
              <Grid xs={10}>
                <Box 
                  sx={{ 
                    p: 2, 
                    backgroundImage:`url(${process.env.PUBLIC_URL + '/images/AppBackgroundLowOpacity.png'})`, 
                    height:'75vh',
                    backgroundRepeat:'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5vh',
                  }}
                >
                  <Grid container xs={12}>
                    <Grid xs={12} height={{xl:40, xs:0}}></Grid>
                    <Grid xs={1} xl={3}></Grid>
                    <Grid xs={10} xl={6} height={{xl:720, lg:650, xs:600}} sx={{display:'flex'}}>
                      <Box   component="form" sx={{borderRadius:'5em', width:'80%',height:'80%', background:'rgba(19, 19, 19, 0.31)', display:'flex', margin:'auto', flexDirection:'column', color:'primary.secondaryText',borderStyle:'solid', borderWidth:'0.2em'}}>
                        <CssTextField type="email" value={email} onChange={handleChangeEmail} required label="Email" sx={{width:'70%', margin:'5vh auto auto auto'}}/>
                        <CssTextField type="password" value={password} onChange={handleChangePassword} required label="Password" sx={{width:'70%', margin:'auto'}}/>
                        <Box sx={{width:'70%', margin:'auto', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                          <Box sx={{ display:'flex', flexDirection:'row'}}>
                            <Checkbox checked={rememberMe} onChange={handleChangeRememberMe} size='large' sx={{ margin:'auto', color: '#EE7EF0','&.Mui-checked': {color: '#EE7EF0',},}}/>
                            <Typography sx={{margin:'auto',color: '#EE7EF0'}}>Remember me</Typography>
                          </Box>
                          <Button onClick={handleForgetPassword} sx={{ color:'#EE7EF0'}}>Forget password?</Button>
                        </Box>
                        <Button onClick={handleLogin} sx={{background:'-webkit-linear-gradient(#EE7EF0, #95449C)', width:'70%', paddingY:'1%', margin:'auto', borderRadius:'2em', border:'none', fontSize:'1.5em' }}>Login</Button>
                        <Box sx={{width:'70%', margin:'auto auto 4vh auto', display:'flex'}}>
                          <Box sx={{margin:'auto', display:'flex', flexDirection:'row'}}>
                            <Typography sx={{margin:'auto',color: '#EE7EF0'}}>Don't have an account? </Typography>
                            <Button onClick={handleSignup} sx={{ color:'#EE7EF0'}}>Signup</Button>
                          </Box>
                        </Box>
                       </Box>
                    </Grid>
                    <Grid xs={1} xl={3}></Grid>
                  </Grid>
                  </Box>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>


            {/*FOOTER*/}
            <Footer/>
      </Box>
    );
};

export default Projects;
