import React, {useState}from 'react';
import { useNavigate } from 'react-router-dom';

//MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const pages = ['home', 'projects', 'services', 'about'];

const MenuItemCSSStyle = styled(Menu)(
  {
    '& .MuiMenu-list': {
      background:'#EE7EF0',
      width:'40vw',
    },
  })

const Navbar = (props) => {
  const navigate = useNavigate();
  

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  const navigateToLink = (link)=>{
    navigate(`/${link}`)
    if(anchorElNav)
      {
        handleCloseNavMenu();
      }
   
  }
    
    return (
      <React.Fragment>
      {/*PC NAVBAR*/}
        <Grid container sx={{display: {xs: 'none', md:'flex'}}}>
        <Grid xs={12} height={20}></Grid>
        <Grid xs={1}></Grid>
        <Grid xs={10}>
          <Box sx={{height:'15vh'}}>
            <Grid container >
              <Grid xs={1.5} sx={{ display:'flex'}}>
                <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="TMDevelop logo" style={{margin:'auto', height:'15vh', width:'8vw'}}></img>
              </Grid>
              <Grid xs={10.5} sx={{display:'flex'}}>
                <Box sx={{height:'80%', width:'100%', borderRadius:'25em', background: '#252525', boxShadow:' 1px -3px 6px 1px rgba(207,207,207,0.1)', margin:'auto', display: { xs: 'none', md: 'flex' }}}>
                {pages.map((page) => (
              <Button
                key={page}
                onClick={(event)=>{
                  navigateToLink(page);
                }}
                sx={{p:'1.5vh 3vw 1.5vh 3vw', background: page === props.activePage ? '-webkit-linear-gradient(#95449C, #46204A)' : '-webkit-linear-gradient(#EE7EF0, #95449C)', my: 2, color: 'white', display: 'block' , margin:'auto', borderRadius:'5em', fontSize:'2vh', '&:hover':{background: '-webkit-linear-gradient(#EE7EF0, #95449C)'}}}
              >
                {page}
              </Button>
            ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={1}></Grid>
      </Grid>

      {/*Mobile NAVBAR*/}
      <Grid container sx={{display: {xs: 'flex', md:'none'}}}>
      <Grid xs={12} height={20}></Grid>
      <Grid xs={1}></Grid>
      <Grid xs={10}>
        <Box sx={{height:'15vh'}}>
          <Grid container >
            <Grid xs={3}  sm={2} sx={{ display:'flex'}}>
              <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="TMDevelop logo" style={{margin:'auto', height:'8vh', width:'20vw'}}></img>
            </Grid>
            <Grid xs={9} sm={10} sx={{display:'flex'}}>
              <Box sx={{height:'10vh', width:'80vw', borderRadius:'25em', background: '#252525', boxShadow:' 1px -3px 6px 1px rgba(207,207,207,0.1)', margin:'auto', display:'flex'}}>
              <Button  
                onClick={handleOpenNavMenu} 
                sx={{display:'flex', flexDirection:'row', margin:'auto',height:'90%', width:'95%',borderRadius:'25em', background:'-webkit-linear-gradient(#EE7EF0, #95449C)'}}
              >
                <Typography sx={{margin:'auto', fontWeight:'bold', fontSize:'180%'}}>{props.activePage}</Typography>
                <ArrowDropDownCircleOutlinedIcon sx={{margin:'-1vh auto auto auto', height:'10vh', width:'10vw'}}/>
              </Button>
              <MenuItemCSSStyle
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                page!==props.activePage &&
                <MenuItem 
                key={page} 
                onClick={()=>{ navigateToLink(page);}}
                sx={{p:'1.5vh 3vw 1.5vh 3vw', background: page === props.activePage ? '#95449C' : '#EE7EF0', my: 2, color: '#252525', display: 'block' , margin:'auto', fontSize:'2vh',fontWeight:'bold', '&:hover':{background: '-webkit-linear-gradient(#EE7EF0, #95449C)'}}}
                >
                  <Typography textAlign="center" sx={{fontSize:'1em'}}>{page}</Typography>
                </MenuItem>
              ))}
            </MenuItemCSSStyle>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid xs={1}></Grid>
    </Grid>
    </React.Fragment>
    );
};

export default Navbar;
