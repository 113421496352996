import React  from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';


export default function BasicModal(props) {

  return (

    props.isMobile
    ?
    //MOBILE
    <Modal
    open={props.open}
    onClose={props.handleCloseModal}
    sx={{
      display:{xs:'flex', md:'none'},
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90vw',
      height:'90%',
    }}
    >
      <Box sx={{display:'flex', width:'100%', height:'100%', backgroundColor:'primary.main', border: 'none', boxShadow: 24}}>
            <Grid container>
                <Grid xs={12} height={15}></Grid>
                <Grid xs={9.5}></Grid>
                <Grid xs={1} sx={{display:'flex'}}>
                  <Button onClick={props.handleCloseModal} sx={{margin:'0 auto auto auto', p:1, fontWeight:'bold', color:'white', fontSize:'5vw'}}>✕</Button>
                </Grid>
                <Grid xs={0.5}></Grid>
                <Grid xs={0.5}></Grid>
                <Grid xs={4} sx={{display:'flex', justifyContent:'top'}}>
                <img src={process.env.PUBLIC_URL +'/images/' + props.modalImage} alt={`${props.modalTitle} icon`} style={{ margin:'0 auto',height:'10vh', width:'10vh'}}/>
                </Grid>
                <Grid xs={7}>
                <Typography variant="h5" color="primary.mainText" sx={{ fontSize:{md:'120%', xl:'160%'}, textAlign:'left', fontWeight:'bold'}}>
                {props.modalTitle}
                </Typography>
                <Typography color="primary.mainText" sx={{fontSize:{md:'80%', xl:'120%'}, textAlign:'left'}}>
                {props.modalDescription}
                </Typography>
                <Typography color="primary.mainText" sx={{fontSize:{md:'80%', xl:'120%'}, textAlign:'left'}}>
                Made with:
                </Typography>
                <Box sx={{display:'flex', alignItems:'left'}}>
                    {
                        props.modalMadeWith.map((madewithIcon,index)=>{
                          let toolTitle = madewithIcon.replace("-icon.png", "");
                            return(
                              <Tooltip title={toolTitle} key={`madewithicon${index}`} placement="bottom" arrow>
                              <img src={process.env.PUBLIC_URL + '/images/' + madewithIcon} alt={madewithIcon} style={{ width:'3vh', margin:'auto 1% auto 0'}}/>
                              </Tooltip>);
                        })
                    }
                </Box>
                </Grid>
                <Grid xs={0.5}></Grid>
                <Grid xs={12} height={20}></Grid>
                <Grid xs={0.5}></Grid>
                <Grid xs={11}>
                <Typography color="primary.mainText" sx={{fontSize:{md:'80%', xl:'120%'}, textAlign:'left'}}>
                Screenshots:
                </Typography>
                </Grid> 
                <Grid xs={0.5}></Grid>
                <Grid xs={12} height={15}></Grid>
                <Grid xs={0.5}></Grid>
                <Grid xs={11}>
                <Box sx={{display:'flex', alignItems:'left', overflow: 'auto'}}>
                    {
                        props.modalScreenshot.map((screenshot,index)=>{
                            return(
                                <img key={`madewithicon${index}`} src={process.env.PUBLIC_URL + '/images/' + screenshot} alt={screenshot} style={{ height:'25vh', margin:'auto 1% auto 0'}}/>
                            );
                        })
                    }
                </Box>
                </Grid>
                <Grid xs={0.5}></Grid>
                <Grid xs={12} height={80}></Grid>
            </Grid>
             </Box>
    </Modal>
    :
    //PC
    <Modal
    open={props.open}
    onClose={props.handleCloseModal}
    sx={{
      display:{xs:'none', md:'flex'},
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50vw',
      height:'80%',
    }}
    >
      <Box sx={{display:'flex', width:'100%', height:'100%', backgroundColor:'primary.main', border: 'none', boxShadow: 24}}>
            <Grid container>
                <Grid xs={12} height={45}></Grid>
                <Grid xs={1}></Grid>
                <Grid xs={2} sx={{display:'flex'}}>
                <img src={process.env.PUBLIC_URL +'/images/' + props.modalImage} alt={`${props.modalTitle} icon`} style={{ margin:'auto',height:'10vh', width:'10vh'}}/>
                </Grid>
                <Grid xs={7}>
                <Typography variant="h5" color="primary.mainText" sx={{ fontSize:{md:'120%', xl:'160%'}, textAlign:'left', fontWeight:'bold'}}>
                {props.modalTitle}
                </Typography>
                <Typography color="primary.mainText" sx={{fontSize:{md:'80%', xl:'120%'}, textAlign:'left'}}>
                {props.modalDescription}
                </Typography>
                <Typography color="primary.mainText" sx={{fontSize:{md:'80%', xl:'120%'}, textAlign:'left'}}>
                Made with:
                </Typography>
                <Box sx={{display:'flex', alignItems:'left'}}>
                    {
                        props.modalMadeWith.map((madewithIcon,index)=>{
                          let toolTitle = madewithIcon.replace("-icon.png", "");
                            return(
                              <Tooltip title={toolTitle} key={`madewithicon${index}`} placement="bottom" arrow>
                              <img src={process.env.PUBLIC_URL + '/images/' + madewithIcon} alt={madewithIcon} style={{ width:'3vh', margin:'auto 1% auto 0'}}/>
                              </Tooltip>);
                        })
                    }
                </Box>
                </Grid>
                <Grid xs={1} sx={{display:'flex'}}>
                  <Button onClick={props.handleCloseModal} sx={{margin:'0 auto auto auto', p:1, fontWeight:'bold', color:'white', fontSize:'1vw'}}>✕</Button>
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={12} height={45}></Grid>
                <Grid xs={1.5}></Grid>
                <Grid xs={10.5}>
                <Typography color="primary.mainText" sx={{fontSize:{md:'80%', xl:'120%'}, textAlign:'left'}}>
                Screenshots:
                </Typography>
                </Grid> 
                <Grid xs={12} height={45}></Grid>
                <Grid xs={1.5}></Grid>
                <Grid xs={9}>
                <Box sx={{display:'flex', alignItems:'left', overflow: 'auto'}}>
                    {
                        props.modalScreenshot.map((screenshot,index)=>{
                            return(
                                <img key={`madewithicon${index}`} src={process.env.PUBLIC_URL + '/images/' + screenshot} alt={screenshot} style={{ height:'25vh', margin:'auto 1% auto 0'}}/>
                            );
                        })
                    }
                </Box>
                </Grid>
                <Grid xs={1.5}></Grid>
                <Grid xs={12} height={80}></Grid>
            </Grid>
             </Box>
    </Modal>
  );
}