import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';



export default function ServiceCard(props) {
  const navigate = useNavigate();

  const CalculateProject = ()=>{
    navigate(`/calculator`)
  }

  return (
    props.isMobile
    ?
    //MOBILE
    <Card sx={{ height:{xs:'100%', sm:'100%'}, background: '-webkit-linear-gradient(#C453B8, #95449C)'}}>
      <CardContent>
        <Box sx={{display:'flex', width:'100%'}}>
            <Grid container>
                <Grid xs={3} sx={{display:'flex', marginBottom:'5%'}}>
                <img src={process.env.PUBLIC_URL +'/images/' + props.serviceUrl} alt={`${props.serviceTitle} icon`} style={{ margin:'2% auto', height:'100%'}}/>
                </Grid>
                <Grid xs={9}>
                <Typography variant="h5" color="primary.mainText" sx={{ fontSize:{xs:'120%', sm:'160%'}, textAlign:'center', fontWeight:'bold', marginBottom:'5%'}}>
                {props.serviceTitle}
                </Typography>
                </Grid>
                <Grid xs={12}>
                <Typography color="primary.mainText" sx={{fontSize:{xs:'80%', sm:'120%'}, marginBottom:'5%', textAlign:'center'}}>
                {props.serviceDescription}
                </Typography>
                </Grid>
                <Grid xs={12} height={10}></Grid>
            </Grid>
             </Box>
      </CardContent>
      <CardActions sx={{display:'flex'}}>
        <Button onClick={CalculateProject} size="large" sx={{backgroundColor:'white', margin:'auto auto 10% auto', fontSize:'1em', borderRadius:'1em'}}>Quote your {props.activeService === 0 ? 'website' : 'game'}</Button>
      </CardActions>
    </Card>
    :
    //PC
    <Card sx={{ height:{xl:'45vh', md:'45vh'}, background: '-webkit-linear-gradient(#C453B8, #95449C)'}}>
      <CardContent>
        <Box sx={{display:'flex', width:'100%'}}>
            <Grid container>
                <Grid xs={3}></Grid>
                <Grid xs={6} height={40} sx={{display:'flex', marginBottom:'5%'}}>
                <img src={process.env.PUBLIC_URL +'/images/' + props.serviceUrl} alt={`${props.serviceTitle} icon`} style={{ margin:'2% auto', height:'100%'}}/>
                </Grid>
                <Grid xs={3}></Grid>
                <Grid xs={12} height={45}>
                <Typography variant="h5" color="primary.mainText" sx={{ fontSize:{md:'120%', xl:'160%'}, textAlign:'center', fontWeight:'bold', marginBottom:'5%'}}>
                {props.serviceTitle}
                </Typography>
                </Grid>
                <Grid xs={12} height={{xl:180,md:140}}>
                <Typography color="primary.mainText" sx={{fontSize:{md:'80%', xl:'120%'}, marginBottom:'5%', textAlign:'center'}}>
                {props.serviceDescription}
                </Typography>
                </Grid>
            </Grid>
             </Box>
      </CardContent>
      <CardActions sx={{display:'flex', height:'10vh'}}>
        <Button onClick={CalculateProject} size="large" sx={{width:'12vw',backgroundColor:'white', margin:'auto', fontSize:{md:'80%',xl:'100%'}, borderRadius:'1em'}}>Try our {props.activeService === 0 ? 'website' : 'game'} quote calculator</Button>
      </CardActions>
    </Card>
  );
}