import React, { useState } from 'react';
import './Services.css';

//COMPONENT
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import CalculatorForm from '../components/CalculatorFormFinal.js';

//MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

const ProjectCalculator = () => {
  const calculatorCategories = ['App', 'Website', 'Game'];
  const questions = [
    //app
    [],
    //website
    [
      {
      questionLabel: 'What type of website are you looking for?',
      questionChoices:[
          'Blog Website',
          'Business Website',
          'E-commerce Website',
          'Custom Website'
      ],
      questionChoicesImage:[
        'BlogWebsite.png',
        'BusinessWebsite.png',
        'ShopWebsite.png',
        'CustomWebsite.png'
    ],
    questionChoicesValue:[
      300,
      350,
      350,
      500
    ],
    questionItemName:[
      'Blog Website Development',
      'Business Website  Development',
      'E-commerce Website  Development',
      'Custom Website  Development'
    ]
      },
      {
      questionLabel: 'What design do you want for your website?',
      questionChoices:[
          'Custom design',
          'Use a template',
          'I do not need one',
          'Help me decide'
      ],
      questionChoicesImage:[
        'CustomWebsite.png',
        'TemplateDesign.png',
        'NoDesign.png',
        'HelpDesign.png'
      ],
      questionChoicesValue:[
      100,
      25,
      0,
      50
      ],
      questionItemName:[
        'Custom design',
        'Template design',
        'No design',
        'Design assistance'
      ]
      },
      {
      questionLabel: 'How big is your website?',
      questionChoices:[
        'Less than 3 pages or 50 products',
        'Less than 10 pages or 100 products',
        'More than 10 pages or 200 products',
        'Help me decide'
      ],
      questionChoicesImage:[
      'SmallProject.png',
      'MediumProject.png',
      'BigProject.png',
      'HelpDesign.png'
      ],
      questionChoicesValue:[
      0,
      150,
      500,
      300
      ],
      questionItemName:[
        'Less than 3 pages or 50 products',
        'Less than 10 pages or 100 products',
        'More than 10 pages or 200 products',
        'Pages assistance'
      ]
      },
      {
      questionLabel: 'Will you take payments on your website?',
      questionChoices:[
      'Yes',
      'No',
      'Help me decide'
      ],
      questionChoicesImage:[
      'YesPayment.png',
      'NoPayment.png',
      'HelpDesign.png'
      ],
      questionChoicesValue:[
      200,
      0,
      50
      ],
      questionItemName:[
        'Payment Integration',
        'No Payment Integration',
        'Payment integration assistance'
      ]
      },
      {
      questionLabel: 'Do you want your website to be integrated with other websites or apps?',
      questionChoices:[
      'Yes',
      'No',
      'Help me decide'
      ],
      questionChoicesImage:[
      'YesPayment.png',
      'NoPayment.png',
      'HelpDesign.png'
      ],
      questionChoicesValue:[
      300,
      0,
      50
      ],
      questionItemName:[
        'Other websites and apps integration',
        'No Integration',
        'Integration assistance'
      ]
      },
      {
      questionLabel: 'Do you want users to login or create an account in your website?',
      questionChoices:[
      'Yes',
      'No',
      'Help me decide'
      ],
      questionChoicesImage:[
      'YesPayment.png',
      'NoPayment.png',
      'HelpDesign.png'
      ],
      questionChoicesValue:[
      200,
      0,
      50
      ],
      questionItemName:[
        'Account management',
        'No Account management',
        'Account management assistance'
      ]
      },
      {
      questionLabel: 'Will you need a database for your website?',
      questionChoices:[
      'Yes',
      'No',
      'Help me decide'
      ],
      questionChoicesImage:[
      'YesPayment.png',
      'NoPayment.png',
      'HelpDesign.png'
      ],
      questionChoicesValue:[
      200,
      0,
      50
      ],
      questionItemName:[
        'Database',
        'No Database',
        'Database assistance'
      ]
      },
      {
      questionLabel: 'What is the current stage for your website?',
      questionChoices:[
      'It is still an idea',
      'I have sketch ready',
      'It is in development',
      'It is already created'
      ],
      questionChoicesImage:[
      'IdeaStage.png',
      'SketchStage.png',
      'InDevelopmentStage.png',
      'LiveStage.png'
      ],
      questionChoicesValue:[
      300,
      350,
      350,
      500
      ],
      questionItemName:[
        'Website Planning, Designing, Development, Testing, and Maintenance',
        'Website Designing, Development, Testing, and Maintenance',
        'Website Development, Testing, and Maintenance',
        'Website Development, Testing, and Maintenance',
      ]
      },
      {
      questionLabel: 'How would you want to pay us?',
      questionChoices:[
      '1 time payment',
      '2 time payment',
      'monthly payment (6)',
      'monthly payment (12)'
      ],
      questionChoicesImage:[
      'OneTimePayment.png',
      'TwoTimePayment.png',
      'MonthlyPayment.png',
      'MonthlyPayment.png'
      ],
      questionChoicesValue:[
      0,
      0,
      0,
      0
      ],
      questionItemName:[
        '1 time payment',
        '2 time payment',
        'monthly payment (6)',
        'monthly payment (12)'
      ]
      },
      {
      questionLabel: 'Done! What do you want to do next?',
      questionChoices:[
      ],
      questionChoicesImage:[
      ],
      questionChoicesValue:[
      ],
      questionItemName:[]
      }
    ],
    //games
    [
      {
      questionLabel: 'What type of game are you looking for?',
      questionChoices:[
          'Mobile Game',
          'Console Game',
          'PC Game',
          'Web Game'
      ],
      questionChoicesImage:[
        'BlogWebsite.png',
        'BusinessWebsite.png',
        'ShopWebsite.png',
        'CustomWebsite.png'
    ],
    questionChoicesValue:[
      300,
      350,
      350,
      500
    ],
    questionItemName:[
      'Blog Website Development',
      'Business Website  Development',
      'E-commerce Website  Development',
      'Custom Website  Development'
    ]
      },
      {
      questionLabel: 'What design do you want for your website?',
      questionChoices:[
          'Custom design',
          'Use a template',
          'I do not need one',
          'Help me decide'
      ],
      questionChoicesImage:[
        'CustomWebsite.png',
        'TemplateDesign.png',
        'NoDesign.png',
        'HelpDesign.png'
      ],
      questionChoicesValue:[
      100,
      25,
      0,
      50
      ],
      questionItemName:[
        'Custom design',
        'Template design',
        'No design',
        'Design assistance'
      ]
      },
      {
      questionLabel: 'How big is your website?',
      questionChoices:[
        'Less than 3 pages or 50 products',
        'Less than 10 pages or 100 products',
        'More than 10 pages or 200 products',
        'Help me decide'
      ],
      questionChoicesImage:[
      'SmallProject.png',
      'MediumProject.png',
      'BigProject.png',
      'HelpDesign.png'
      ],
      questionChoicesValue:[
      0,
      150,
      500,
      300
      ],
      questionItemName:[
        'Less than 3 pages or 50 products',
        'Less than 10 pages or 100 products',
        'More than 10 pages or 200 products',
        'Pages assistance'
      ]
      },
      {
      questionLabel: 'Will you take payments on your website?',
      questionChoices:[
      'Yes',
      'No',
      'Help me decide'
      ],
      questionChoicesImage:[
      'YesPayment.png',
      'NoPayment.png',
      'HelpDesign.png'
      ],
      questionChoicesValue:[
      200,
      0,
      50
      ],
      questionItemName:[
        'Payment Integration',
        'No Payment Integration',
        'Payment integration assistance'
      ]
      },
      {
      questionLabel: 'Do you want your website to be integrated with other websites or apps?',
      questionChoices:[
      'Yes',
      'No',
      'Help me decide'
      ],
      questionChoicesImage:[
      'YesPayment.png',
      'NoPayment.png',
      'HelpDesign.png'
      ],
      questionChoicesValue:[
      300,
      0,
      50
      ],
      questionItemName:[
        'Other websites and apps integration',
        'No Integration',
        'Integration assistance'
      ]
      },
      {
      questionLabel: 'Do you want users to login or create an account in your website?',
      questionChoices:[
      'Yes',
      'No',
      'Help me decide'
      ],
      questionChoicesImage:[
      'YesPayment.png',
      'NoPayment.png',
      'HelpDesign.png'
      ],
      questionChoicesValue:[
      200,
      0,
      50
      ],
      questionItemName:[
        'Account management',
        'No Account management',
        'Account management assistance'
      ]
      },
      {
      questionLabel: 'Will you need a database for your website?',
      questionChoices:[
      'Yes',
      'No',
      'Help me decide'
      ],
      questionChoicesImage:[
      'YesPayment.png',
      'NoPayment.png',
      'HelpDesign.png'
      ],
      questionChoicesValue:[
      200,
      0,
      50
      ],
      questionItemName:[
        'Database',
        'No Database',
        'Database assistance'
      ]
      },
      {
      questionLabel: 'What is the current stage for your website?',
      questionChoices:[
      'It is still an idea',
      'I have sketch ready',
      'It is in development',
      'It is already created'
      ],
      questionChoicesImage:[
      'IdeaStage.png',
      'SketchStage.png',
      'InDevelopmentStage.png',
      'LiveStage.png'
      ],
      questionChoicesValue:[
      300,
      350,
      350,
      500
      ],
      questionItemName:[
        'Website Planning, Designing, Development, Testing, and Maintenance',
        'Website Designing, Development, Testing, and Maintenance',
        'Website Development, Testing, and Maintenance',
        'Website Development, Testing, and Maintenance',
      ]
      },
      {
      questionLabel: 'How would you want to pay us?',
      questionChoices:[
      '1 time payment',
      '2 time payment',
      'monthly payment (6)',
      'monthly payment (12)'
      ],
      questionChoicesImage:[
      'OneTimePayment.png',
      'TwoTimePayment.png',
      'MonthlyPayment.png',
      'MonthlyPayment.png'
      ],
      questionChoicesValue:[
      0,
      0,
      0,
      0
      ],
      questionItemName:[
        '1 time payment',
        '2 time payment',
        'monthly payment (6)',
        'monthly payment (12)'
      ]
      },
      {
      questionLabel: 'Done! What do you want to do next?',
      questionChoices:[
      ],
      questionChoicesImage:[
      ],
      questionChoicesValue:[
      ],
      questionItemName:[]
      }
    ]
];

  const [activeCalculatorPage,setActiveCalculatorPage] = useState(1);

  const [activeStep,setActiveStep] = useState(0);
  const [calculatedCode,setCalculatedCode] = useState([0,0,0,0,0,0,0,0,0]);
  const [calculatedPrice,setCalculatedPrice] = useState(0);
  const [preCalculatedCode,setPreCalculatedCode] = useState([0,0,0,0,0,0,0,0,0]);
  const [preCalculatedPrice,setPreCalculatedPrice] = useState(0);

  const [receiptItems,setReceiptItems]= useState([]);
  const [receiptPrices,setReceiptPrices]= useState([]);

  const [paymentMethod,setPaymentMethod]=useState(0);

  
  const changeCalculatorPage = (numberChosen)=>{
    setActiveCalculatorPage(Number(numberChosen))
    resetCalculation();
  }

  const changePaymentMethod =(paymentmethodid)=>{
    setPaymentMethod(paymentmethodid);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const setCalculation = (newVal,priceToAdd,itemToAdd)=> {
    var precalculatedcode = calculatedCode;
    setPreCalculatedCode(precalculatedcode);
    precalculatedcode[activeStep] = newVal;
    setCalculatedCode(precalculatedcode);

    var precalculatedprice = calculatedPrice;
    setPreCalculatedPrice(precalculatedprice);
    precalculatedprice += priceToAdd;
    setCalculatedPrice(precalculatedprice);

    if(priceToAdd !== 0)
      {
        var prereceiptitems = receiptItems;
        prereceiptitems.push(itemToAdd);
        setReceiptItems(prereceiptitems);

        var prereceiptprices = receiptPrices;
        prereceiptprices.push(priceToAdd);
        setReceiptPrices(prereceiptprices);
      }
    


    if(activeStep + 1 === questions[activeCalculatorPage].length){

    }
    else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  const undoCalculation = ()=>{
    setCalculatedCode(preCalculatedCode);
    setCalculatedPrice(preCalculatedPrice);

    var prereceiptitems = receiptItems;
    prereceiptitems.pop();
    setReceiptItems(prereceiptitems);
    
    var prereceiptprices = receiptPrices;
    prereceiptprices.pop();
    setReceiptPrices(prereceiptprices);

    if(activeStep + 1 === questions[activeCalculatorPage].length)
      {
        setPaymentMethod(0);
      }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const resetCalculation = () => {
    setCalculatedCode([0,0,0,0,0,0,0,0,0]);
    setCalculatedPrice(0);
    setActiveStep(0);
    setReceiptItems([]);
    setReceiptPrices([]);
  };

    return (
      <Box sx={{ flexGrow: 1 , overflowX:'hidden'}}>
            {/*NAVBAR*/}
            <Navbar activePage='about'/>

            {/*PC BODY*/}
            <Grid container sx={{display:{xs: 'none', md: 'flex'}}}>
              <Grid xs={12} height={20}></Grid>
              <Grid xs={1}></Grid>
              <Grid xs={10}>
                <Box 
                  sx={{ 
                    p: 2, 
                    backgroundImage:`url(${process.env.PUBLIC_URL + '/images/PlainBackground.png'})`, 
                    height:'75vh',
                    backgroundRepeat:'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5vh',
                    position:'relative'
                  }}
                ><Grid container >
                    <Grid xs={12} height={40}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={5}>
                        <ButtonGroup orientation="horizontal" variant="button" sx={{display:'flex'}}>
                            {calculatorCategories.map((calculatorcategory,index)=>{
                                return(
                                    <Button onClick={()=>{changeCalculatorPage(index)}} key={`calculator-page-${index}`} sx={{ margin:'auto'}}>
                                      {
                                        activeCalculatorPage === index 
                                        ? <Typography sx={{ fontSize:{xl:'400%',lg:'230%'}, fontWeight:'bold', background: 'white', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', textTransform:'none'}}>
                                        {calculatorcategory}
                                      </Typography>
                                        : <Typography sx={{textDecoration:'underline', textDecorationColor:'#C453B8', fontSize:{xl:'325%',lg:'200%'}, fontWeight:'bold', background: '-webkit-linear-gradient(#C453B8, #95449C)', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent',textTransform:'none' }}>
                                        {calculatorcategory}
                                      </Typography>
                                      }
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                    </Grid>
                    <Grid xs={6.5}></Grid>
                    <Grid xs={12} height={40}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={6}>
                      {
                        questions[activeCalculatorPage].length > 0 
                        ?
                        <CalculatorForm activeStep={activeStep} formCategory={activeCalculatorPage} questions={questions} setCalculationFunction={setCalculation} undoCalculationFunction={undoCalculation} resetCalculationFunction={resetCalculation} changePaymentMethod={changePaymentMethod}/>
                        :
                        <Box sx={{width:'100%', height:'55vh', backgroundColor:'#232323', borderRadius:'2em'}}>
                          <Grid container spacing={1} sx={{overflow:'scroll', height:'98%'}}>
                            <Grid xs={12} height={5}></Grid>
                            <Grid xs={1}></Grid>
                            <Grid xs={10} sx={{display:'flex', flexDirection:'column'}}>
                              <Typography variant="h5" color="primary.mainText" sx={{ margin:'auto', fontSize:{md:'180%', xl:'230%'}, textAlign:'center', fontWeight:'bold'}}>
                              Service will be open soon.
                              </Typography>
                              <Typography  color="primary.mainText" sx={{ margin:'auto', fontSize:{md:'150%', xl:'200%'}, textAlign:'center'}}>
                              Subscribe to receive updates.
                              </Typography>
                            </Grid>
                            <Grid xs={1}></Grid>
                            <Grid xs={12} height={5}></Grid>
                          </Grid>
                        </Box>
                      }
                    </Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={4.5}>
                      <Box sx={{width:'100%', height:'40vh', backgroundColor:'#232323', borderRadius:'2em'}}>
                          <Grid container spacing={1} sx={{overflow:'scroll', height:'98%'}}>
                            <Grid xs={12} height={5}></Grid>
                            {
                              receiptItems.map((item,index)=>{
                                return(
                                  <React.Fragment key={`${item}-${index}`}>
                                    <Grid xs={1}></Grid>
                                    <Grid xs={7} sx={{display:'flex', flexDirection:'column'}}>
                                      <Typography variant="h5" color="primary.mainText" sx={{ width:'100%', fontSize:{md:'100%', xl:'150%'}, textAlign:'left'}}>
                                      {item}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={3} sx={{display:'flex', flexDirection:'column'}}>
                                      <Typography variant="h5" color="primary.mainText" sx={{ width:'100%', fontSize:{md:'100%', xl:'150%'}, textAlign:'right', }}>
                                      {`$${receiptPrices[index]}.00`}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={1}></Grid>
                                  </React.Fragment>
                                )
                              })
                            }
                            <Grid xs={12} height={5}></Grid>
                          </Grid>
                      </Box>

                      <Box sx={{marginTop:'5%', width:'100%', height:'10vh', backgroundColor:'#232323', borderRadius:'2em'}}>
                          <Grid container  spacing={1} sx={{overflow:'scroll', height:'98%'}}>
                            <Grid xs={12} height={5}></Grid>
                            <Grid xs={1}></Grid>
                            {
                              calculatedPrice > 0
                              &&<Grid xs={10} sx={{display:'flex'}}>
                              <Typography variant="h5" color="primary.mainText" sx={{ width:'100%', fontSize:{md:'130%', xl:'160%'}, textAlign:'center', fontWeight:'bold'}}>
                                {paymentMethod === 0
                                ?`$${calculatedPrice}.00 - 30% off = $${Math.round(calculatedPrice * (1 - 0.3))}.00 (one payment)`
                                :paymentMethod === 1
                                ?`$${calculatedPrice}.00 - 20% off = $${Math.round((calculatedPrice * (1 - 0.2))/2)}.00 (two payments)`
                                :paymentMethod === 2
                                ?`$${calculatedPrice}.00 - 10% off = $${Math.round((calculatedPrice * (1 - 0.1))/6)}.00/month (for 6 months)`
                                :`$${calculatedPrice}.00 = $${Math.round((calculatedPrice * (1 - 0.1))/12)}.00/month (for 12  months)`
                                }
                              </Typography>
                            </Grid>
                            }
                            
                             <Grid xs={1}></Grid>
                            <Grid xs={12} height={5}></Grid>
                          </Grid>
                      </Box>
                    </Grid>
                    <Grid xs={0.5}></Grid>
                  </Grid>
                  </Box>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>

             {/*MOBILE BODY*/}
             <Grid container sx={{display:{xs: 'flex', md: 'none'}}}>
              <Grid xs={12} height={20}></Grid>
              <Grid xs={1}></Grid>
              <Grid xs={10}>
                <Box 
                  sx={{ 
                    p: 2, 
                    backgroundImage:`url(${process.env.PUBLIC_URL + '/images/PlainBackground.png'})`, 
                    height:'70vh',
                    backgroundRepeat:'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5vh',
                    position:'relative'
                  }}
                ><Grid container >
                    <Grid xs={12} height={10}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={11}>
                        <ButtonGroup orientation="horizontal" variant="button" sx={{display:'flex'}}>
                            {calculatorCategories.map((calculatorcategory,index)=>{
                                return(
                                    <Button onClick={()=>{changeCalculatorPage(index)}} key={`calculator-page-${index}`} sx={{ margin:'auto'}}>
                                      {
                                        activeCalculatorPage === index 
                                        ? <Typography sx={{ fontSize:{xs:'1em',sm:'2em'}, fontWeight:'bold', background: 'white', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', textTransform:'none'}}>
                                        {calculatorcategory}
                                      </Typography>
                                        : <Typography sx={{textDecoration:'underline', textDecorationColor:'#C453B8', fontSize:{xs:'1em',sm:'2em'}, fontWeight:'bold', background: '-webkit-linear-gradient(#C453B8, #95449C)', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent',textTransform:'none' }}>
                                        {calculatorcategory}
                                      </Typography>
                                      }
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                    </Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={12} height={20}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={11}>
                      <Box sx={{width:'100%', height:'20vh', backgroundColor:'#232323', borderRadius:'2em'}}>
                          <Grid container spacing={1} sx={{overflow:'scroll', height:'98%'}}>
                            <Grid xs={12} height={5}></Grid>
                            {
                              receiptItems.map((item,index)=>{
                                return(
                                  <React.Fragment key={`${item}-${index}`}>
                                    <Grid xs={1}></Grid>
                                    <Grid xs={7} sx={{display:'flex', flexDirection:'column'}}>
                                      <Typography variant="h5" color="primary.mainText" sx={{ width:'100%', fontSize:{xs:'100%', sm:'150%'}, textAlign:'left'}}>
                                      {item}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={3} sx={{display:'flex', flexDirection:'column'}}>
                                      <Typography variant="h5" color="primary.mainText" sx={{ width:'100%', fontSize:{xs:'100%', sm:'150%'}, textAlign:'right', }}>
                                      {`$${receiptPrices[index]}.00`}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={1}></Grid>
                                  </React.Fragment>
                                )
                              })
                            }
                            <Grid xs={12} height={5}></Grid>
                          </Grid>
                      </Box>

                      <Box sx={{marginTop:'5%', width:'100%', height:'10vh', backgroundColor:'#232323', borderRadius:'2em'}}>
                          <Grid container  spacing={1} sx={{overflow:'scroll', height:'98%'}}>
                            <Grid xs={12} height={5}></Grid>
                            <Grid xs={1}></Grid>
                            {
                              calculatedPrice > 0
                              &&<Grid xs={10} sx={{display:'flex'}}>
                              <Typography variant="h5" color="primary.mainText" sx={{ width:'100%', fontSize:{xs:'100%', sm:'110%'}, textAlign:'center', fontWeight:'bold'}}>
                                {paymentMethod === 0
                                ?`$${calculatedPrice}.00 - 30% off = $${Math.round(calculatedPrice * (1 - 0.3))}.00 (one payment)`
                                :paymentMethod === 1
                                ?`$${calculatedPrice}.00 - 20% off = $${Math.round((calculatedPrice * (1 - 0.2))/2)}.00 (two payments)`
                                :paymentMethod === 2
                                ?`$${calculatedPrice}.00 - 10% off = $${Math.round((calculatedPrice * (1 - 0.1))/6)}.00/month (for 6 months)`
                                :`$${calculatedPrice}.00 = $${Math.round((calculatedPrice * (1 - 0.1))/12)}.00/month (for 12  months)`
                                }
                              </Typography>
                            </Grid>
                            }
                            
                             <Grid xs={1}></Grid>
                            <Grid xs={12} height={5}></Grid>
                          </Grid>
                      </Box>
                    </Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={12} height={20}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={11}>
                      {
                        questions[activeCalculatorPage].length > 0 
                        ?
                        <CalculatorForm activeStep={activeStep} formCategory={activeCalculatorPage} questions={questions} setCalculationFunction={setCalculation} undoCalculationFunction={undoCalculation} resetCalculationFunction={resetCalculation} changePaymentMethod={changePaymentMethod} isMobile={true}/>
                        :
                        <Box sx={{width:'100%', height:'32vh', backgroundColor:'#232323', borderRadius:'2em'}}>
                          <Grid container spacing={1} sx={{overflow:'scroll', height:'98%'}}>
                            <Grid xs={12} height={5}></Grid>
                            <Grid xs={1}></Grid>
                            <Grid xs={10} sx={{display:'flex', flexDirection:'column'}}>
                              <Typography variant="h5" color="primary.mainText" sx={{ margin:'auto', fontSize:{md:'180%', xl:'230%'}, textAlign:'center', fontWeight:'bold'}}>
                              Service will be open soon.
                              </Typography>
                              <Typography  color="primary.mainText" sx={{ margin:'auto', fontSize:{md:'150%', xl:'200%'}, textAlign:'center'}}>
                              Subscribe to receive updates.
                              </Typography>
                            </Grid>
                            <Grid xs={1}></Grid>
                            <Grid xs={12} height={5}></Grid>
                          </Grid>
                        </Box>
                      }
                    </Grid>
                    <Grid xs={0.5}></Grid>
                  
                  </Grid>
                  </Box>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>


            {/*FOOTER*/}
            <Footer/>
      </Box>
    );
};

export default ProjectCalculator;
