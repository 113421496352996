import React, { useState } from 'react';
import './Services.css';

//COMPONENT
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import ServiceCard from '../components/ServiceCard.js';

//MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import NativeSelect from '@mui/material/NativeSelect';

const Services = () => {
  const servicePageBackgrounds = ['WebsiteBackgroundLowOpacity.png', 'GameBackgroundLowOpacity.png'];
  const services = {
    website:[ 
      {
        serviceImg:'ecommerce-logo.png',
        title: 'eCommerce Website',
        description: 'Robust online stores for selling products and services. Features: Secure payment gateways, product catalogs, shopping cart, order management, and customer reviews.',
        features: ['Browsing and searching products',
          'Product sorting and filtering',
          'Shopping cart and checkout',
          'Multiple payment options',
          'FAQs and Product Info',
          'Support Chat']
      },
      {
        serviceImg:'busines-logo.png',
        title: 'Business Website',
        description: 'Professional websites tailored to showcase your business. Features: Responsive design, contact forms, service pages, company information, and SEO optimization ',
        features: ['Call to action',
          'text, image, and video contents for your business or brand.',
          'Reviews and testimonials',
          'Contact information'
          ]
      },
      {
        serviceImg:'blog-logo.png',
        title: 'Blog Website',
        description: 'Dynamic platforms for sharing articles, news, and insights. Features: User-friendly CMS, customizable themes, comment sections, social media integration, and SEO tools.',
        features: ['A page for dynamic contents',
          'Social profiles and links',
          'Ad space',
          'Community page']
      },
      {
        serviceImg:'custom-logo.png',
        title: 'Custom Website',
        description: 'Bespoke websites designed to meet unique client needs. Features: Tailored design and functionality, advanced user interactivity, API integration, and high performance.',
        features: [
          'Features depends on the website needs']
      }], 
    game:[ {
      serviceImg:'mobile-logo.png',
      title: 'Mobile Game',
      description: 'Engaging and interactive games for mobile devices. Features: Intuitive touch controls, cross-platform compatibility, in-app purchases, and social media sharing.',
      features: ['Depends on your game needs.']
    },
    {
      serviceImg:'console-logo.png',
      title: 'Console Game',
      description: 'High-quality games designed for gaming consoles. Features: Advanced graphics, multiplayer capabilities, downloadable content (DLC) support, and immersive gameplay.',
      features: ['Depends on your game needs.']
    },
    {
      serviceImg:'pc-logo.png',
      title: 'PC Game',
      description: 'Comprehensive gaming experiences for personal computers. Features: High-definition graphics, customizable controls, online multiplayer, and mod support.',
      features: ['Depends on your game needs.']
    },
    {
      serviceImg:'web-logo.png',
      title: 'Web Game',
      description: 'Browser-based games accessible from any device with an internet connection. Features: Instant play without downloads, cross-platform support, multiplayer options, and social integration.',
      features: ['Depends on your game needs.']
    }]
  };
  const [activeServicePage,setActiveServicePage] = useState(0);

  const changeServicePage = (e)=>{
    setActiveServicePage(Number(e.target.value))
  }


    return (
      <Box sx={{ flexGrow: 1 , overflowX:'hidden'}}>
            {/*NAVBAR*/}
            <Navbar activePage='services'/>

            {/*PC BODY*/}
            <Grid container sx={{display:{xs:'none', md: 'flex'}}}>
              <Grid xs={12} height={20}></Grid>
              <Grid xs={1}></Grid>
              <Grid xs={10}>
                <Box 
                  sx={{ 
                    p: 2, 
                    backgroundImage:`url(${process.env.PUBLIC_URL + '/images/' + servicePageBackgrounds[activeServicePage]})`, 
                    height:'75vh',
                    backgroundRepeat:'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5vh',
                  }}
                >
                  <Grid container>
                    <Grid xs={12} height={40}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={5}>
                      <Typography variant="h1" sx={{ color:'#FFFFFF', fontSize:'4vh', fontWeight:'bold'}}>What do you want to build?</Typography>
                    </Grid>
                    <Grid xs={2}>
                      <NativeSelect
                      value={activeServicePage}
                      onChange={changeServicePage}
                      sx={{width:'100%', textAlign:'center', color:'#C453B8', fontSize:'3.5vh',fontWeight:'bold'}}
                      >
                        <option value={0} sx={{textAlign:'center',fontSize:'3.5vh'}}>Website</option>
                        <option value={1} sx={{textAlign:'center',fontSize:'3.5vh'}}>Games</option>
                      </NativeSelect>
                    </Grid>
                    <Grid xs={3}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={12} height={40}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={11}>
                    <Typography variant="h1" sx={{ color:'#FFFFFF', fontSize:'2.5vh'}}>Whether it is for business,  commercial, or personal use, we can help you from specific to the entire development process from planning, ui/ux design, development, testing, deployment, and maintenance of your app.</Typography>
                    </Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={12} height={40}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={11}>
                    <Box>
                        <Grid container >
                          {
                            activeServicePage === 0
                            ? services.website.map((service,index)=>{
                              return(
                                <React.Fragment key={`${service.title}-service${index}`}>
                                  <Grid xs={0.25}></Grid>
                                  <Grid xs={2.5}>
                                <ServiceCard activeService={activeServicePage} serviceUrl={service.serviceImg} serviceTitle={service.title} serviceDescription={service.description} serviceFeatures={service.features}/>
                                </Grid>
                                <Grid xs={0.25}></Grid>
                                </React.Fragment>
                              )
                            })
                            : services.game.map((service,index)=>{
                              return(
                                <React.Fragment key={`${service.title}-service${index}`}>
                                  <Grid xs={0.25}></Grid>
                                  <Grid xs={2.5}>
                                <ServiceCard serviceUrl={service.serviceImg} serviceTitle={service.title} serviceDescription={service.description} serviceFeatures={service.features}/>
                                </Grid>
                                <Grid xs={0.25}></Grid>
                                </React.Fragment>
                              )
                            })
                          }
                          
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid xs={0.5}></Grid>
                  </Grid>
                  </Box>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>

            {/*MOBILE BODY*/}
            <Grid container sx={{display:{xs:'flex', md: 'none'}}}>
              <Grid xs={1}></Grid>
              <Grid xs={10}>
                <Box 
                  sx={{ 
                    p: 2, 
                    backgroundImage:`url(${process.env.PUBLIC_URL + '/images/' + servicePageBackgrounds[activeServicePage]})`, 
                    height:'70vh',
                    backgroundRepeat:'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5vh',
                  }}
                >
                  <Grid container >
                    <Grid xs={12} height={40}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={10}>
                      <Typography variant="h1" sx={{ color:'#FFFFFF', fontSize:'3vh', fontWeight:'bold'}}>What do you want to build?</Typography>
                      <NativeSelect
                      value={activeServicePage}
                      onChange={changeServicePage}
                      sx={{width:'100%', textAlign:'center', color:'#C453B8', fontSize:'3vh',fontWeight:'bold'}}
                      >
                        <option value={0} sx={{textAlign:'center',fontSize:'1.5vh'}}>Website</option>
                        <option value={1} sx={{textAlign:'center',fontSize:'1.5vh'}}>Games</option>
                      </NativeSelect>
                    </Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={12} height={10}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={11}>
                    <Typography variant="h1" sx={{ color:'#FFFFFF', fontSize:{xs:'1em', sm:'1.5em'}}}>Whether it is for business,  commercial, or personal use, we can help you from specific to the entire development process from planning, ui/ux design, development, testing, deployment, and maintenance of your app.</Typography>
                    </Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={12} height={40}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xs={11}>
                    <Box sx={{height:{xs:'30vh', sm:'38vh'},overflowY:'scroll'}}>
                        <Grid container >
                          {
                            activeServicePage === 0
                            ? services.website.map((service,index)=>{
                              return(
                                <React.Fragment>
                                  <Grid xs={12}>
                                    <ServiceCard activeService={activeServicePage} serviceUrl={service.serviceImg} serviceTitle={service.title} serviceDescription={service.description} serviceFeatures={service.features} isMobile={true}/>
                                  </Grid>
                                  <Grid xs={12} height={10}></Grid>
                                </React.Fragment>
                              )
                            })
                            : services.game.map((service,index)=>{
                              return(
                                <React.Fragment>
                                <Grid xs={12}>
                                <ServiceCard serviceUrl={service.serviceImg} serviceTitle={service.title} serviceDescription={service.description} serviceFeatures={service.features} isMobile={true}/>
                                </Grid>
                                <Grid xs={12} height={10}></Grid>
                                </React.Fragment>
                              )
                            })
                          }
                          
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid xs={0.5}></Grid>
                  </Grid>
                  </Box>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>


            {/*FOOTER*/}
            <Footer/>
      </Box>
    );
};

export default Services;
