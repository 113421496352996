import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';



export default function ProjectCard(props) {
  return (
    props.isMobile 
    ?
    //MOBILE
    <Card onClick={()=>{props.openModalCall(props.projectImg,props.title,props.description,props.madeWithIcons,props.screenshot)}} sx={{ height:'100%', width:'100%', background:'transparent'}}>
      <CardContent>
        <Box sx={{display:'flex', width:'100%'}}>
            <Grid container >
                <Grid xs={1}></Grid>
                <Grid xs={10} sx={{display:'flex'}}>
                <img src={process.env.PUBLIC_URL +'/images/' + props.projectImg} alt={`${props.title} icon`} style={{ margin:'auto',height:'10vh', width:'10vh'}}/>
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={1}></Grid>
                <Grid xs={10}>
                <Typography variant="h5" color="primary.mainText" sx={{ fontSize:{xs:'120%', sm:'160%'}, textAlign:'center', fontWeight:'bold', marginY:'1vh'}}>
                {props.title}
                </Typography>
                <Typography color="primary.mainText" sx={{fontSize:{xs:'90%', sm:'120%'}, textAlign:'center',marginY:'1vh'}}>
                {props.description}
                </Typography>
                <Box sx={{display:'flex', alignItems:'center', marginY:'1vh'}}>
                    {
                        props.madeWithIcons.map((madewithIcon,index)=>{
                            let toolTitle = madewithIcon.replace("-icon.png", "");
                            return(
                              <Tooltip title={toolTitle} key={`madewithicon${index}`} placement="top" arrow>
                                <img src={process.env.PUBLIC_URL + '/images/' + madewithIcon} alt={madewithIcon} style={{ width:'3vh', margin:'auto'}}/>
                                </Tooltip>
                            );
                        })
                    }
                </Box>
                <Typography color="primary.mainText" sx={{fontSize:{xs:'80%', sm:'120%'}, textAlign:'center', marginY:'1vh'}}>
                -{props.status}
                </Typography>
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={12} height={45}></Grid>
            </Grid>
             </Box>
      </CardContent>
    </Card>
    :
    //PC
    <Card onClick={()=>{props.openModalCall(props.projectImg,props.title,props.description,props.madeWithIcons,props.screenshot)}} sx={{ height:'100%', width:'100%', background:'transparent'}}>
      <CardContent>
        <Box sx={{display:'flex', width:'100%'}}>
            <Grid container >
                <Grid xs={1}></Grid>
                <Grid xs={2} sx={{display:'flex'}}>
                <img src={process.env.PUBLIC_URL +'/images/' + props.projectImg} alt={`${props.title} icon`} style={{ margin:'auto',height:'10vh', width:'10vh'}}/>
                </Grid>
                <Grid xs={7}>
                <Typography variant="h5" color="primary.mainText" sx={{ fontSize:{md:'120%', xl:'160%'}, textAlign:'left', fontWeight:'bold'}}>
                {props.title}
                </Typography>
                <Typography color="primary.mainText" sx={{fontSize:{md:'80%', xl:'120%'}, textAlign:'left'}}>
                {props.description}
                </Typography>
                <Box sx={{display:'flex', alignItems:'left'}}>
                    {
                        props.madeWithIcons.map((madewithIcon,index)=>{
                            let toolTitle = madewithIcon.replace("-icon.png", "");
                            return(
                              <Tooltip title={toolTitle} key={`madewithicon${index}`} placement="top" arrow>
                                <img src={process.env.PUBLIC_URL + '/images/' + madewithIcon} alt={madewithIcon} style={{ width:'3vh', margin:'auto 1% auto 0'}}/>
                                </Tooltip>
                            );
                        })
                    }
                </Box>
                <Typography color="primary.mainText" sx={{fontSize:{md:'80%', xl:'120%'}, textAlign:'left'}}>
                -{props.status}
                </Typography>
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={12} height={45}></Grid>
            </Grid>
             </Box>
      </CardContent>
    </Card>

    
  );
}