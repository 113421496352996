import React, { useState } from 'react';
import './Services.css';

//COMPONENT
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import ProjectCard from '../components/ProjectCard.js';
import ProjectModal from '../components/ProjectModal.js';

//MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton'; 

const Projects = () => {
  const servicePageBackgrounds = ['AppBackgroundLowOpacity.png', 'GameBackgroundLowOpacity.png','WebsiteBackgroundLowOpacity.png'];
  const projectCategories = ['Apps', 'Games', 'Websites'];
  const projectsToShow = {
    website:[ 
      {
        projectImg:'emil-kalinowski-logo.png',
        title: 'Emil Kalinowski',
        description: 'He is a YouTube financial superstar that helps you understand how the 2007 malfunction of the monetary system - and its continuing disorder - affects finance, economy, politics and society.',
        madeWithIcons: ['mongodb-icon.png',
          'express-icon.png',
          'react-icon.png',
          'node-icon.png'],
        status:'visit www.emilkalinowski.net',
        screenshot:['Emil-Kalinowski-Screenshot-1.png','Emil-Kalinowski-Screenshot-2.png','Emil-Kalinowski-Screenshot-3.png','Emil-Kalinowski-Screenshot-4.png','Emil-Kalinowski-Screenshot-5.png','Emil-Kalinowski-Screenshot-6.png','Emil-Kalinowski-Screenshot-7.png','Emil-Kalinowski-Screenshot-8.png']
      },
      {
        projectImg:'sccc-logo.png',
        title: 'Southern California Career College',
        description: 'Your educational partner that is committed in providing a supportive and collaborative learning environment where students can thrive and achieve their goals.',
        madeWithIcons: ['mongodb-icon.png',
          'express-icon.png',
          'react-icon.png',
          'node-icon.png'],
        status:'In development',
        screenshot:['sccc-screenshot-1.png','sccc-screenshot-2.png','sccc-screenshot-3.png','sccc-screenshot-4.png','sccc-screenshot-5.png','sccc-screenshot-6.png','sccc-screenshot-7.png','sccc-screenshot-8.png','sccc-screenshot-9.png','sccc-screenshot-10.png','sccc-screenshot-11.png']
      },
      {
        projectImg:'trh-logo.png',
        title: 'Top Rated Homecare Services',
        description: 'A compassionate, quality, and compliant team that provide quality home care services for over hundreds of families. ',
        madeWithIcons: ['mongodb-icon.png',
          'express-icon.png',
          'react-icon.png',
          'node-icon.png'],
        status:'In development',
        screenshot:['trh-screenshot-1.png','trh-screenshot-2.png','trh-screenshot-3.png','trh-screenshot-4.png','trh-screenshot-5.png','trh-screenshot-6.png','trh-screenshot-7.png','trh-screenshot-8.png','trh-screenshot-9.png','trh-screenshot-10.png','trh-screenshot-11.png','trh-screenshot-12.png']
      },
      {
        projectImg:'elebot-v-logo.png',
        title: 'Elebot',
        description: 'An innovative NFT blockchain card game where you command robots with the power to control the elements.',
        madeWithIcons: ['mongodb-icon.png',
          'express-icon.png',
          'react-icon.png',
          'node-icon.png'],
        status:'visit www.elebot-v.com',
        screenshot:['elebot-v-screenshot-1.png','elebot-v-screenshot-2.png','elebot-v-screenshot-3.png','elebot-v-screenshot-4.png','elebot-v-screenshot-5.png']
      }], 
    game:[ {
        projectImg:'sounds-like-logo.png',
        title: 'Sounds Like',
        description: 'A fun sound guessing game for android and ios.',
        madeWithIcons: ['unity-icon.png',
          'firebase-icon.png',
          'apple-icon.png',
          'android-icon.png'],
        status:'Discontinued',
        screenshot:['Sounds-Like-screenshot-1.png','Sounds-Like-screenshot-2.png','Sounds-Like-screenshot-3.png','Sounds-Like-screenshot-4.png','Sounds-Like-screenshot-5.png','Sounds-Like-screenshot-6.png']
    },
    {
        projectImg:'elebot-logo.png',
        title: 'Elebot',
        description: 'An innovative NFT blockchain card game where you command robots with the power to control the elements.',
        madeWithIcons: ['unity-icon.png',
          'express-icon.png',
          'react-icon.png',
          'node-icon.png'],
        status:'In development',
        screenshot:['']
    },
    {
        projectImg:'wreck-battle-arena-logo.png',
        title: 'Wreck Battle Arena',
        description: 'A game of custom cars, driving, and wrecking.',
        madeWithIcons: ['unity-icon.png',
          'firebase-icon.png',
          'apple-icon.png',
          'android-icon.png'],
        status:'In development',
        screenshot:['']
    }],
    app:[
    {
        projectImg:'my-meal-inventory-logo.png',
        title: 'My Meal Inventory',
        description: 'Keep track of your daily ingredients, recipes, and meals.',
        madeWithIcons: ['unity-icon.png',
            'firebase-icon.png',
            'apple-icon.png',
            'android-icon.png'],
        status:'In development',
        screenshot:['mmi-screenshot-1.png','mmi-screenshot-2.png','mmi-screenshot-3.png','mmi-screenshot-4.png','mmi-screenshot-5.png','mmi-screenshot-6.png']
    }
    ]
  };
  const [activeProjectPage,setActiveProjectPage] = useState(0);

  const changeProjectPage = (numberChosen)=>{
    setActiveProjectPage(Number(numberChosen))
    console.log(`active number ${numberChosen}`)
  }

  const [openModal, setOpenModal] = useState(false);
  const [modalImage,setModalImage] = useState('');
  const [modalTitle,setModalTitle] = useState('');
  const [modalDescription,setModalDescription] = useState('');
  const [modalMadeWith,setModalMadeWith] = useState(['']);
  const [modalScreenshot,setModalScreenshot] = useState(['']);
  const handleOpenModal = (modalImg,modalTitle,modalDescription,madeWith,Screenshots) => {
    setOpenModal(true);
    setModalImage(modalImg);
    setModalTitle(modalTitle);
    setModalDescription(modalDescription);
    setModalMadeWith(madeWith);
    setModalScreenshot(Screenshots);
  };
  const handleCloseModal = () => setOpenModal(false);


    return (
      <Box sx={{ flexGrow: 1 , overflowX:'hidden'}}>
            {/*NAVBAR*/}
            <Navbar activePage='projects'/>

            {/*MODAL*/}
            <ProjectModal sx={{display:{xs: 'none', md: 'flex'}}} open={openModal} handleCloseModal={handleCloseModal} modalImage={modalImage} modalTitle={modalTitle} modalDescription={modalDescription} modalMadeWith={modalMadeWith} modalScreenshot={modalScreenshot} isMobile={false}/>
            <ProjectModal sx={{display:{xs: 'flex', md: 'none'}}} open={openModal} handleCloseModal={handleCloseModal} modalImage={modalImage} modalTitle={modalTitle} modalDescription={modalDescription} modalMadeWith={modalMadeWith} modalScreenshot={modalScreenshot} isMobile={true}/>


            {/*PC BODY*/}
            <Grid container sx={{display:{xs: 'none', md: 'flex'}}}>
              <Grid xs={12} height={20}></Grid>
              <Grid xs={1}></Grid>
              <Grid xs={10}>
                <Box 
                  sx={{ 
                    p: 2, 
                    backgroundImage:`url(${process.env.PUBLIC_URL + '/images/' + servicePageBackgrounds[activeProjectPage]})`, 
                    height:'75vh',
                    backgroundRepeat:'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5vh',
                  }}
                >
                  <Grid container >
                    <Grid xs={12} height={40}></Grid>
                    <Grid xs={0.5}></Grid>
                    <Grid xl={9} lg={7.5}>
                        <Paper style={{height:'70vh', overflow: 'auto', width:'100%', backgroundColor:'transparent'}}>
                            <List>
                            {activeProjectPage === 0 
                            ? projectsToShow.app.map((projectApps,index)=>{
                                return(
                                    <ListItemButton key={`project-category-${index}`} sx={{width:{xl:'80%', lg:'100%'}, justifyContent:'start', alignSelf:'flex-start'}}>
                                    <ProjectCard openModalCall={handleOpenModal} projectImg={projectApps.projectImg} title={projectApps.title} description={projectApps.description} madeWithIcons={projectApps.madeWithIcons} status={projectApps.status} screenshot={projectApps.screenshot}/>
                                    </ListItemButton>
                                );
                            })
                            :
                            activeProjectPage === 1
                            ?projectsToShow.game.map((projectGames,index)=>{
                              return(
                                  <ListItemButton key={`project-category-${index}`} sx={{width:{xl:'80%', lg:'100%'}, justifyContent:'start', alignSelf:'flex-start'}}>
                                  <ProjectCard openModalCall={handleOpenModal} projectImg={projectGames.projectImg} title={projectGames.title} description={projectGames.description} madeWithIcons={projectGames.madeWithIcons} status={projectGames.status} screenshot={projectGames.screenshot}/>
                                  </ListItemButton>
                              );
                          })
                            :projectsToShow.website.map((projectWebsites,index)=>{
                              return(
                                  <ListItemButton key={`project-category-${index}`} sx={{width:{xl:'80%', lg:'100%'}, justifyContent:'start', alignSelf:'flex-start'}}>
                                  <ProjectCard openModalCall={handleOpenModal} projectImg={projectWebsites.projectImg} title={projectWebsites.title} description={projectWebsites.description} madeWithIcons={projectWebsites.madeWithIcons} status={projectWebsites.status} screenshot={projectWebsites.screenshot}/>
                                  </ListItemButton>
                              );
                          })
                            }
                            </List>
                        </Paper>
                    </Grid>
                    <Grid xl={2} lg={3.5}>
                        <ButtonGroup orientation="vertical" variant="button" sx={{display:'flex',  }}>
                            {projectCategories.map((projectcategory,index)=>{
                                return(
                                    <Button onClick={()=>{changeProjectPage(index)}} key={`project-category-${index}`} sx={{  justifyContent:'end', alignSelf:'flex-end'}}>
                                      {
                                        activeProjectPage === index 
                                        ? <Typography sx={{ fontSize:{lg:'400%',md:'250%'}, fontWeight:'bold', background: 'white', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent' }}>
                                        {projectcategory}
                                      </Typography>
                                        : <Typography sx={{textDecoration:'underline', textDecorationColor:'#C453B8', fontSize:{lg:'350%', md:'300%'}, fontWeight:'bold', background: '-webkit-linear-gradient(#C453B8, #95449C)', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent' }}>
                                        {projectcategory}
                                      </Typography>
                                      }
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                    </Grid>
                    <Grid xs={0.5}></Grid>
                  </Grid>
                  </Box>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>

            {/*MOBILE BODY*/}
            <Grid container sx={{display:{xs: 'flex', md: 'none'}}}>
              <Grid xs={1}></Grid>
              <Grid xs={10}>
                <Box 
                  sx={{ 
                    p: 2, 
                    backgroundImage:`url(${process.env.PUBLIC_URL + '/images/' + servicePageBackgrounds[activeProjectPage]})`, 
                    height:'70vh',
                    backgroundRepeat:'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5vh',
                  }}
                >
                  <Grid container >
                    <Grid xs={12} height={20}></Grid>
                    <Grid xs={12} sx={{display:'flex'}}>
                        <ButtonGroup orientation="row" variant="button" sx={{display:'flex', margin:'auto' }}>
                            {projectCategories.map((projectcategory,index)=>{
                                return(
                                    <Button onClick={()=>{changeProjectPage(index)}} key={`project-category-${index}`} sx={{  justifyContent:'end', alignSelf:'flex-end'}}>
                                      {
                                        activeProjectPage === index 
                                        ? <Typography sx={{ fontSize:{xs:'120%',sm:'300%'}, fontWeight:'bold', background: 'white', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent' }}>
                                        {projectcategory}
                                      </Typography>
                                        : <Typography sx={{textDecoration:'underline', textDecorationColor:'#C453B8', fontSize:{xs:'120%', sm:'250%'}, fontWeight:'bold', background: '-webkit-linear-gradient(#C453B8, #95449C)', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent' }}>
                                        {projectcategory}
                                      </Typography>
                                      }
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                    </Grid>
                    <Grid xl={12}>
                        <Paper style={{height:'60vh', overflow: 'auto', width:'100%', backgroundColor:'transparent'}}>
                            <List>
                            {activeProjectPage === 0 
                            ? projectsToShow.app.map((projectApps,index)=>{
                                return(
                                    <ListItemButton key={`project-category-${index}`} sx={{width:{xs:'100%', sm:'100%'}, justifyContent:'start', alignSelf:'flex-start'}}>
                                    <ProjectCard openModalCall={handleOpenModal} projectImg={projectApps.projectImg} title={projectApps.title} description={projectApps.description} madeWithIcons={projectApps.madeWithIcons} status={projectApps.status} screenshot={projectApps.screenshot} isMobile={true}/>
                                    </ListItemButton>
                                );
                            })
                            :
                            activeProjectPage === 1
                            ?projectsToShow.game.map((projectGames,index)=>{
                              return(
                                  <ListItemButton key={`project-category-${index}`} sx={{width:{xs:'100%', sm:'100%'}, justifyContent:'start', alignSelf:'flex-start'}}>
                                  <ProjectCard openModalCall={handleOpenModal} projectImg={projectGames.projectImg} title={projectGames.title} description={projectGames.description} madeWithIcons={projectGames.madeWithIcons} status={projectGames.status} screenshot={projectGames.screenshot} isMobile={true}/>
                                  </ListItemButton>
                              );
                          })
                            :projectsToShow.website.map((projectWebsites,index)=>{
                              return(
                                  <ListItemButton key={`project-category-${index}`} sx={{width:{xl:'80%', lg:'100%'}, justifyContent:'start', alignSelf:'flex-start'}}>
                                  <ProjectCard openModalCall={handleOpenModal} projectImg={projectWebsites.projectImg} title={projectWebsites.title} description={projectWebsites.description} madeWithIcons={projectWebsites.madeWithIcons} status={projectWebsites.status} screenshot={projectWebsites.screenshot} isMobile={true}/>
                                  </ListItemButton>
                              );
                          })
                            }
                            </List>
                        </Paper>
                    </Grid>
                  </Grid>
                  </Box>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>


            {/*FOOTER*/}
            <Footer/>
      </Box>
    );
};

export default Projects;
